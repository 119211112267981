import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "Shared/components/Card/Card"
import React from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import { useUpdatePanelistPassword } from "~/api/generated/usabilityhub-components"

const ChangePasswordFormSchema = Yup.object({
  current_password: Yup.string().required(),
  password: Yup.string().required(),
  password_confirmation: Yup.string().required(),
})

type ChangePasswordForm = Yup.InferType<typeof ChangePasswordFormSchema>

export const PanelistChangePasswordForm: React.FC = () => {
  const toast = useToast()
  const { mutateAsync: changePassword } = useUpdatePanelistPassword({
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      })
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
  })
  const allFields = watch()
  const isDisabled = Object.values(allFields).some((v) => v.trim() === "")

  const onSubmit = async (values: ChangePasswordForm) => {
    try {
      await changePassword({ body: values })
    } catch (error) {
      // handled by the mutation's onError
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader size="lg">Change your password</CardHeader>
        <CardBody>
          <FormControl isRequired isInvalid={!!errors.current_password}>
            <FormLabel>Current password</FormLabel>
            <Input
              type="password"
              autoComplete="off"
              {...register("current_password")}
            />
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.current_password}>
            <FormLabel>New password</FormLabel>
            <Input
              type="password"
              autoComplete="off"
              {...register("password")}
            />
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.current_password}>
            <FormLabel>Confirm new password</FormLabel>
            <Input
              type="password"
              autoComplete="off"
              {...register("password_confirmation")}
            />
          </FormControl>
          <CardFooter>
            <Button
              type="submit"
              isDisabled={isDisabled}
              colorScheme="brand.primary"
              isLoading={isSubmitting}
            >
              Change password
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
    </form>
  )
}
