import { BoxProps } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import image from "Images/app-illustrations/incentive.png"
import { AnnouncementBanner } from "Shared/components/AnnouncementBanner"
import { useDismissibleNotice } from "Shared/hooks/useDismissibleNotice"
import React, { useRef } from "react"
import {
  RecordingsOnboarding,
  RecordingsOnboardingHandle,
} from "./RecordingsOnboarding"

export const RecordingsAnnouncementBanner: React.FC<BoxProps> = (props) => {
  const { isOpen: bannerIsOpen, dismiss: dismissBanner } = useDismissibleNotice(
    "recordings_announcement_banner"
  )

  const { isLoading, dismiss: seenOnboarding } = useDismissibleNotice(
    "usercrowd_recordings_onboarding"
  )

  const onboarding = useRef<RecordingsOnboardingHandle>(null)

  return (
    <>
      <AnnouncementBanner
        bg="ds.background.accent.yellow.subtle"
        image={image}
        actions={
          <>
            <Button
              variant="primary"
              onClick={() => onboarding.current?.onOpen()}
            >
              Learn more
            </Button>
            <Button onClick={dismissBanner}>Dismiss</Button>
          </>
        }
        {...props}
        isOpen={bannerIsOpen}
        onClose={dismissBanner}
      >
        <strong>Coming soon.</strong> Unlock extra rewards with our new way of
        participating in research.
      </AnnouncementBanner>
      {!isLoading && (
        <RecordingsOnboarding ref={onboarding} onSeen={seenOnboarding} />
      )}
    </>
  )
}
