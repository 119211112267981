import { Flex, Heading, Spinner, Text, useToast } from "@chakra-ui/react"
import chimeAudioPath from "Audio/chime.mp3"
import { RoutedLinkButton } from "Components/button/link-button"
import { getEnvState } from "JavaScripts/state"
import { playAudioPath } from "Utilities/audio"
import { pluralizeWithCount } from "Utilities/string"
import { difference } from "lodash"
import React, { useEffect, useState } from "react"
import {
  useCurrentPanelistInfo,
  useGetPanelistSettings,
  useListUsercrowdOrderAssignments,
} from "~/api/generated/usabilityhub-components"

import { usePushNotifications } from "Shared/hooks/usePushNotifications"
import { EmptyListPlaceholder } from "UserCrowd/components/EmptyListPlaceholder"
import { PracticeTestCard } from "UserCrowd/views/dashboard/PracticeTestCard"
import { ROUTES } from "UserCrowd/views/routes"
import { ModeratedStudyCard } from "./ModeratedStudyCard"
import { UsabilityTestCard } from "./UsabilityTestCard"

export const AssignmentsHeading = () => (
  <Heading as="h3" fontSize="lg" fontWeight="medium" color="text.primary">
    Available studies
    <Spinner size="xs" ml={2} />
  </Heading>
)

export const AssignmentList: React.FC = () => {
  const toast = useToast()
  const { PANELIST_ORDER_ASSIGNMENT_POLL_INTERVAL_IN_SECONDS: pollTime } =
    getEnvState()

  const { data: assignments } = useListUsercrowdOrderAssignments(
    {},
    {
      refetchInterval: pollTime * 1000,
    }
  )
  const [previousAssignments, setPreviousAssignments] = useState<
    string[] | null
  >(null)

  const { data: user } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )
  const { data: settings } = useGetPanelistSettings({})

  const practiceTestRequired = user?.practice_test_state === "not_started"
  const isEmailNotificationEnabled =
    settings?.notify_assignments_by_email ?? false
  const { isEnabled: isPushNotificationEnabled } = usePushNotifications()

  // Fire off an alert whenever new tests come in
  useEffect(() => {
    if (!assignments) return

    const assignmentIds = assignments.panelist_order_assignments
      .map((a) => a.id.toString())
      .concat(assignments.moderated_study_order_assignments.map((a) => a.id))

    const newAssignments = difference(assignmentIds, previousAssignments ?? [])

    if (newAssignments.length) setPreviousAssignments(assignmentIds)

    if (newAssignments.length > 0 && previousAssignments) {
      toast({
        status: "success",
        title: `You have ${pluralizeWithCount(
          newAssignments.length,
          "new assignment",
          "new assignments"
        )}`,
      })

      void playAudioPath(chimeAudioPath)
    }
  }, [assignments, previousAssignments, toast])

  const noAssignments =
    !practiceTestRequired &&
    (!assignments ||
      (assignments.panelist_order_assignments.length === 0 &&
        assignments.moderated_study_order_assignments.length === 0))

  if (noAssignments) {
    return (
      <ListEmpty
        isPushNotificationEnabled={isPushNotificationEnabled}
        isEmailNotificationEnabled={isEmailNotificationEnabled}
      />
    )
  }

  return (
    <Flex direction="column" gap={4}>
      {practiceTestRequired && <PracticeTestCard />}

      {assignments?.moderated_study_order_assignments.map((assignment) => (
        <ModeratedStudyCard key={assignment.id} assignment={assignment} />
      ))}

      {assignments?.panelist_order_assignments.map((assignment) => (
        <UsabilityTestCard key={assignment.id} assignment={assignment} />
      ))}
    </Flex>
  )
}

interface ListEmptyProps {
  readonly isPushNotificationEnabled: boolean
  readonly isEmailNotificationEnabled: boolean
}

const ListEmpty = ({
  isPushNotificationEnabled,
  isEmailNotificationEnabled,
}: ListEmptyProps) => (
  <EmptyListPlaceholder heading="No available studies">
    {isPushNotificationEnabled || isEmailNotificationEnabled ? (
      <Text fontSize="md" color="text.secondary">
        You{"\u2019"}ll be notified as soon as a study becomes available.
      </Text>
    ) : (
      <>
        <Text fontSize="md" color="text.secondary">
          Enable notifications to be notified when a study becomes available.
        </Text>
        <RoutedLinkButton
          to={ROUTES.SETTINGS.NOTIFICATIONS.path}
          colorScheme="brand.primary"
        >
          Notification settings
        </RoutedLinkButton>
      </>
    )}
  </EmptyListPlaceholder>
)
