import { Text } from "@chakra-ui/react"
import { Link } from "@chakra-ui/react"
import { Tag } from "DesignSystem/components"
import { Cursor04SolidIcon } from "Shared/icons/untitled-ui/Cursor04SolidIcon"
import { AssignmentCard } from "UserCrowd/components/AssignmentCard/AssignmentCard"
import React from "react"
import { ROUTES } from "../routes"

export const PracticeTestCard: React.FC = () => {
  return (
    <AssignmentCard
      studyTypeBadge={
        <Tag
          variant="subtle"
          colorScheme="purple"
          label="Test"
          leftIcon={Cursor04SolidIcon}
        />
      }
      estimatedLengthText="Approx 1 minute"
      incentiveText="Practice"
      tags={["Any device"]}
      participateUrl={ROUTES.DASHBOARD.START_PRACTICE_TEST.path}
    >
      <Text textStyle="ds.paragraph.primary" color="ds.text.default">
        Practice tests help you get familiar with our platform and the types of
        questions you’ll encounter. Its completion is mandatory before you can
        receive real studies, and your response will be reviewed by the
        UserCrowd Team.
      </Text>

      <Text textStyle="ds.paragraph.secondary" color="ds.text.default">
        Note: There is no financial compensation for completing practice tests.{" "}
        <Link
          isExternal
          href="#"
          colorScheme="brand.primary"
          sx={{ "&:not(:hover)": { textDecoration: "none" } }}
        >
          Learn more
        </Link>
      </Text>
    </AssignmentCard>
  )
}
