import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Progress,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { RoutedLinkButton } from "Components/button/link-button"
import { MINIMUM_REQUEST } from "Constants/testers"
import { PaypalLogoIcon } from "Shared/icons/PaypalLogoIcon"
import { Link } from "UserCrowd/components/Link"
import { Card } from "UserCrowd/components/TestersCard"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import { isBlank } from "Utilities/values"
import { isEmpty } from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import {
  useGetPanelistSettings,
  usePanelistPayouts,
  usePanelistStats,
  useRequestPayout,
} from "~/api/generated/usabilityhub-components"
import { OpenPayoutList } from "./OpenPayoutList"
import { SlowPayoutAlert } from "./SlowPayoutAlert"

const SLOW_PAYOUT_THRESHOLD_DAYS = 30

export const PayoutsRoute: React.FC = () => {
  const toast = useToast()
  const { data: panelistStats } = usePanelistStats({})
  const { data: payouts } = usePanelistPayouts({})
  const { data: settings } = useGetPanelistSettings({})

  const { mutate: requestPayout } = useRequestPayout({
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      })
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  if (!panelistStats || !settings) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const estimatedPayoutReviewTimeInDays =
    panelistStats?.estimated_payout_review_time_in_days
  const isPaypalInfoMissing = isBlank(settings.paypal_email_address)
  const minimumRequestInDollars = centsToDollars(
    creditsToCents(MINIMUM_REQUEST)
  )
  const currentPayoutProgress =
    Math.min(panelistStats.credit_balance.current, minimumRequestInDollars) /
    minimumRequestInDollars
  const openPayouts = payouts?.payouts.filter(
    (payout) => payout.status === "open"
  )

  return (
    <UserCrowdDefaultPageLayout>
      <Helmet>
        <title>Payouts • UserCrowd</title>
      </Helmet>
      <UserCrowdNavbar variant="inner-page" />
      <TestersHeader heading="Payouts" />

      {isPaypalInfoMissing && <MissingPaypalInfoBanner />}

      <Grid templateColumns="2fr 1fr" gap={5}>
        <Box>
          <Card>
            <Flex gap={5} align="center">
              <Heading as="h3" fontSize="3xl" fontWeight="semibold">
                {formatDollars(panelistStats.credit_balance.current)}
              </Heading>

              <Progress
                value={currentPayoutProgress * 100}
                variant="round"
                size="sm"
                flexGrow={1}
              />

              <Button
                isDisabled={currentPayoutProgress < 1}
                onClick={() => requestPayout({})}
                colorScheme="brand.primary"
                loadingText="Please wait"
                flexShrink={0}
              >
                Request a payout
              </Button>
            </Flex>
          </Card>

          <Heading as="h2" fontSize="md" fontWeight="medium" py={4}>
            Payout history
          </Heading>

          {!isEmpty(openPayouts) &&
            estimatedPayoutReviewTimeInDays &&
            estimatedPayoutReviewTimeInDays > SLOW_PAYOUT_THRESHOLD_DAYS && (
              <SlowPayoutAlert
                estimatedPayoutReviewTimeInDays={
                  estimatedPayoutReviewTimeInDays
                }
              />
            )}

          {payouts ? (
            <OpenPayoutList payouts={payouts.payouts} />
          ) : (
            <Center minH={40}>
              <Spinner />
            </Center>
          )}
        </Box>

        <Flex direction="column" gap={2}>
          <Heading as="h2" fontSize="md" fontWeight="medium">
            How do I get paid?
          </Heading>

          <Text fontSize="md" color="text.secondary">
            UserCrowd makes payments via PayPal only. You can set your PayPal
            email address in your{" "}
            <Link to={ROUTES.SETTINGS.ACCOUNT.path}>account settings</Link>.
          </Text>

          <Heading as="h2" fontSize="md" fontWeight="medium" mt={4}>
            How long does it take to get paid?
          </Heading>

          <Text fontSize="md" color="text.secondary">
            Requests are normally reviewed and (if approved) paid within 30
            days.
          </Text>
        </Flex>
      </Grid>
    </UserCrowdDefaultPageLayout>
  )
}

const MissingPaypalInfoBanner: React.FC = () => (
  <Card display="flex" alignItems="center" flexWrap="wrap" gap={5} mb={5}>
    {/* this colour is particular to this icon usage and not part of our palette */}
    <Flex
      bg="#B9E8FF"
      w={12}
      h={12}
      align="center"
      justify="center"
      rounded="4px"
    >
      <PaypalLogoIcon />
    </Flex>

    <Box flexGrow="1">
      <Heading as="h4" fontWeight="medium">
        No PayPal information
      </Heading>
      <Text>Enter your PayPal account email before you request a payout.</Text>
    </Box>

    <RoutedLinkButton
      to={ROUTES.SETTINGS.ACCOUNT.path}
      colorScheme="brand.primary"
    >
      Go to settings
    </RoutedLinkButton>
  </Card>
)
