import { Image, Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Badge, Button, Heading } from "DesignSystem/components"
import { DisplayModal } from "UserCrowd/components/DisplayModal/DisplayModal"
import React from "react"
import { Navigate, useNavigate } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../../routes"
import illustrationSrc from "./start-practice-test-illustration.png"

export const StartPracticeTestRoute: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, status } = useCurrentPanelistInfo({})

  const onClose = () => {
    navigate(ROUTES.DASHBOARD.path)
  }

  if (status !== "success") return null
  if (user.practice_test_state !== "not_started") {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <DisplayModal isOpen onClose={onClose}>
      <DisplayModal.TwoPaneContent
        content={
          <Image
            h="full"
            w="full"
            objectFit="cover"
            roundedTop="16px"
            roundedBottom={[0, "16px"]}
            src={illustrationSrc}
          />
        }
        controls={
          <>
            <Button variant="secondary" onClick={onClose}>
              Later
            </Button>

            <Button as="a" variant="primary" href={ROUTES.PRACTICE_TEST.path}>
              Let{"\u2019"}s go!
            </Button>
          </>
        }
      >
        <Badge variant="subtle" label="PRACTICE TEST" />

        <Heading as="h2" color="ds.text.default" textStyle="ds.display.primary">
          You{"\u2019"}re about to begin a practice test
        </Heading>

        <Text color="ds.text.default" textStyle="ds.paragraph.emphasized">
          Your response will be reviewed by the UserCrowd Team, so please read
          all questions and answer them carefully.
        </Text>

        <Text color="ds.text.subtle" textStyle="ds.paragraph.primary">
          Note: There is no financial compensation for completing practice
          tests.{" "}
          <Link
            isExternal
            href={Constants.HELP_CENTER_PRACTICE_TESTS_URL}
            colorScheme="brand.primary"
            sx={{ "&:not(:hover)": { textDecoration: "none" } }}
          >
            Learn more
          </Link>
        </Text>
      </DisplayModal.TwoPaneContent>
    </DisplayModal>
  )
}
