import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import {
  AssignmentList,
  AssignmentsHeading,
} from "UserCrowd/components/AssignmentList/AssignmentList"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { USERCROWD_NAVBAR_HEIGHT } from "UserCrowd/constants"
import { sample } from "lodash"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Outlet } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ProfileCompletionBanner } from "./ProfileCompletionBanner/ProfileCompletionBanner"
import { TestersMetrics } from "./TestersMetrics"
import { UpcomingBookingList } from "./UpcomingBookingList"
import { OnboardingDrawer } from "./onboarding-drawer/OnboardingDrawer"
import {
  OnboardingDrawerContextProvider,
  useOnboardingDrawerContext,
} from "./onboarding-drawer/OnboardingDrawerContextProvider"
import { testerGuidelines } from "./testerGuidelines"

export const DashboardRoute: React.FC = () => {
  const [guideline] = useState(() => sample(testerGuidelines))

  const { data: user } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )

  const shouldInlineOnboardingDrawer = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { fallback: "xl" }
  )

  const currentHour = new Date().getHours()
  let greeting: string
  if (currentHour >= 4 && currentHour < 12) {
    greeting = "Morning"
  } else if (currentHour < 18) {
    greeting = "Afternoon"
  } else {
    greeting = "Evening"
  }

  return (
    <>
      <UserCrowdNavbar variant="dashboard" />
      <OnboardingDrawerContextProvider>
        <OnboardingDrawerAndContentWrapper
          displayAsDrawer={!shouldInlineOnboardingDrawer}
        >
          <UserCrowdDefaultPageLayout>
            <Flex direction="column" gap={8} sx={cqContainer()}>
              <Helmet>
                <title>Dashboard • UserCrowd</title>
              </Helmet>
              {/* The dashboard renders some modals on their own nested routes. See UserCrowd.tsx for more info. */}
              <Outlet />
              <TestersHeader
                heading={
                  user
                    ? `${greeting}, ${user.preferred_name ?? user.name}`
                    : greeting
                }
                showBackButton={false}
                mb={0}
              />
              {shouldInlineOnboardingDrawer && <OnboardingDrawer />}
              <TestersMetrics />
              <ProfileCompletionBanner />
              <Grid
                templateColumns="1fr"
                sx={cq("50rem", { gridTemplateColumns: "2fr 1fr" })}
                gap={8}
              >
                <Flex flexDirection="column" gap={4}>
                  <AssignmentsHeading />
                  <AssignmentList />
                </Flex>
                <Flex direction="column" gap={4}>
                  <UpcomingBookingList />
                  {guideline && (
                    <Box>
                      <Heading fontSize="lg" fontWeight="medium" mb={3}>
                        {guideline.heading}
                      </Heading>
                      {guideline.guideline.map((paragraph) => (
                        <Text
                          key={paragraph}
                          fontSize="16px"
                          color="text.secondary"
                        >
                          {paragraph}
                        </Text>
                      ))}
                    </Box>
                  )}
                </Flex>
              </Grid>
            </Flex>
          </UserCrowdDefaultPageLayout>
        </OnboardingDrawerAndContentWrapper>
      </OnboardingDrawerContextProvider>
    </>
  )
}

const OnboardingDrawerAndContentWrapper: React.FC<
  React.PropsWithChildren<{
    displayAsDrawer?: boolean
  }>
> = ({ displayAsDrawer, children }) => {
  const { showDrawer } = useOnboardingDrawerContext()

  if (!showDrawer) return children
  if (!displayAsDrawer) return children

  return (
    <Flex minH="100dvh" alignItems="stretch">
      <Box mt={USERCROWD_NAVBAR_HEIGHT} flexBasis="21rem" flexShrink={0}>
        <OnboardingDrawer isDrawer />
      </Box>
      {children}
    </Flex>
  )
}
